import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './page.css'
const Third_Page = () => {
    return (
        <div>
            <Container>

                <Row>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(42).jpg')} />
                            <Card.Title>Chickpeas</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(43).jpg')} />
                            <Card.Title>Green Split Peas</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(44).jpg')} />
                            <Card.Title>Kidney Beans</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(45).jpg')} />
                            <Card.Title>Masur</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(47).jpg')} />
                            <Card.Title>Mung</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(48).jpg')} />
                            <Card.Title>Pigoan Pea</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(49).jpg')} />
                            <Card.Title>Red Lentile</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(50).jpg')} />
                            <Card.Title>Soya Beans</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(51).jpg')} />
                            <Card.Title>Urad</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(52).jpg')} />
                            <Card.Title>Yellow Split Peas</Card.Title>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Third_Page

