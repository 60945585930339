import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config/apiConfig";
import { MutatingDots } from "react-loader-spinner";
import video from "../../Components/WhatsApp Video 2024-04-27 at 19.33.17_3ce777e8.mp4";
import "./style.css";
const MainCarousel = () => {
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <video src={video} autoPlay muted loop />
    </div>
  );
};

export default MainCarousel;
