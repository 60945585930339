import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './page.css'
const Fifth_Page = () => {
    return (
        <div>
            <Container>

                <Row>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(61).jpg')} />
                            <Card.Title>Almond</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(62).jpg')} />
                            <Card.Title>Black Raisin</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(63).jpg')} />
                            <Card.Title>Cashew</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(64).jpg')} />
                            <Card.Title>Golden Long Raisin</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(65).jpg')} />
                            <Card.Title>Golden Small Raisin</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(67).jpg')} />
                            <Card.Title>Walnut</Card.Title>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Fifth_Page

