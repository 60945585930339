import { Button, Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../config/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/AddContact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.status >= 200 && response.status < 300) {
        console.log("Submitted successfully!");
        toast.success(
          "Submitted successfully. We'll answer as soon as possible"
        );
        // Reset the form data
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        // window.location.reload();
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };


  return (
    <>
      <Container>
        <div className="flex justify-center py-5 container-xxl">
          <div className="container">
          
             
                <div className="container">
                  <h2 className="mb-4 text-2xl font-bold">Get in Touch</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <TextField
                        label="Name"
                        className="w-full"
                        variant="outlined"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-4">
                      <TextField
                        label="Email"
                        className="w-full"
                        variant="outlined"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <TextField
                        label="Subject"
                        className="w-full"
                        variant="outlined"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <TextField
                        label="Message"
                        className="w-full"
                        variant="outlined"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: "black" }}
                      className="w-full px-4 py-2 text-white bg-black rounded mb-44 w hover:bg-primary-dark focus:outline-none focus:bg-primary-dark"
                    >
                      Submit
                    </Button>
                  </form>
               
              </div>
            
          </div>
        </div>
      </Container>
    </>
  );
}

export default Contact;
