import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './page.css'
const Sixth_Page = () => {
    return (
        <div>
            <Container>

                <Row>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />

                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Sixth_Page

