import React from "react";


import Contact from "./Contact";
import Navigation from "../Navigation/Navigation";
// import { IoLogoWhatsapp } from "react-icons/io";
export const MainContact = () => {
  return (
    <>

      <Navigation/>      
      <Contact />
      
    </>
  );
};
