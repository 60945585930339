import React from "react";

const Footer = () => {
  return (
    <footer
      className="py-4 text-gray-300 bg-black d-flex justify-center "
    ><div>
  <div className="container mx-auto text-center text-white h3">
        <p> Reach Us</p>
      </div>
      <div className="w-full px-4 py-2 text-white bg-black rounded  hover:bg-primary-dark focus:outline-none focus:bg-primary-dark"
      >Address :-  219,Tulsishyam Soc,Shyamdham Nana Varachha,Surat,395006</div> 
      <div className="mt-4 w-full px-4 py-2 text-white bg-black rounded  hover:bg-primary-dark focus:outline-none focus:bg-primary-dark">Contact :- +91 9558 612 112</div>
      <div className="mb-6 mt-4 w-full px-4 py-2 text-white bg-black rounded  hover:bg-primary-dark focus:outline-none focus:bg-primary-dark">Email :- Thinkskyinternational@gmail.com </div>
      <div className="w-full px-4 py-2 h4  text-white">Call Us Now</div>
      <div className="w-full px-4 py-2 h4  text-white"> +91 9558 612 112</div>

      <div className="container mx-auto text-center text-white h5 mb-0">
        <hr></hr>
        <p>&copy;Copyright 2024 TSI - ThinkSky International | Powered by TSI - ThinkSky International</p>
      </div>
    </div>
    
    </footer>
  );
};

export default Footer;
