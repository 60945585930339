import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './page.css'
const Second_Page = () => {
  return (
    <div>
          <Container>

<Row>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(30).jpg')} />
    <Card.Title>Cardarmom</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(31).jpg')} />
    <Card.Title>Cinnamon</Card.Title> 
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(32).jpg')} />
    <Card.Title>Cloves</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(33).jpg')} />
    <Card.Title>Coriander Seed</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(34).jpg')} />
    <Card.Title>Cumin</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(35).jpg')} />
    <Card.Title>Fennel</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(36).jpg')} />
    <Card.Title>Garlic</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(37).jpg')} />
    <Card.Title>Ginger</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(39).jpg')} />
    <Card.Title>Jaggery</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(40).jpg')} />
    <Card.Title>Red Chilli Powder</Card.Title>
    </Card>
  </Col>
  <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
    <Card style={{ width: '18rem', }}>
    <Card.Img className='img_edit' variant="top" src={require('../img/ca(41).jpg')} />
    <Card.Title>Turmaric</Card.Title>
    </Card>
  </Col>
</Row>
</Container>
    </div>
  )
}

export default Second_Page

