import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './page.css'
const Forth_Page = () => {
    return (
        <div>
            <Container>

                <Row>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(53).jpg')} />
                            <Card.Title>Grapes</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(54).jpg')} />
                            <Card.Title>Mangoes</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(56).jpg')} />
                            <Card.Title>Orange</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(57).jpg')} />
                            <Card.Title>Pineapple</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(58).jpg')} />
                            <Card.Title>Promegranate</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(59).jpg')} />
                            <Card.Title>Banana</Card.Title>
                        </Card>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
                        <Card style={{ width: '18rem', }}>
                            <Card.Img className='img_edit' variant="top" src={require('../img/ca(60).jpg')} />
                            <Card.Title>Apple</Card.Title>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Forth_Page

