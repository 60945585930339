import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import "./carousel.css";

const responsive = {
  0: { items: 3 },
  568: { items: 5 },
  1024: { items: 6 },
};

const items = [
  <div className="item" data-value="1">
    <img src={require("../img/ca(22).jpeg")} alt="" />
  </div>,
  <div className="item" data-value="2">
    <img src={require("../img/ca(23).jpeg")} alt="" />
  </div>,
  <div className="item" data-value="3">
    <img src={require("../img/ca(24).png")} alt="" />
  </div>,
  <div className="item" data-value="4">
    <img src={require("../img/ca(25).png")} alt="" />
  </div>,
  <div className="item" data-value="5">
    <img src={require("../img/ca(27).jpeg")} alt="" />
  </div>,
  <div className="item" data-value="6">
    <img src={require("../img/ca(28).png")} alt="" />
  </div>,
  <div className="item" data-value="7">
    <img src={require("../img/ca(29).jpeg")} alt="" />
  </div>,
   <div className="item" data-value="7">
   <img src={require("../img/apeda.jpeg")} alt="" />
 </div>,
];
const SectionCarousal = () => {
  // const sildBack = () => setActionIndex(actionIndex - 1);
  // const sildNex = () => setActionIndex(actionIndex + 1);

  const [loading, setLoading] = useState(true);

  return (
    <>
      <div className="mt-6 ms-4">
        <AliceCarousel
          className="dot-no"
          items={items}
          disableButtonsControls
          disableSlideOnTouch
          autoPlay={true}
          infinite
          responsive={responsive}
        />
      </div>
    </>
  );
};

export default SectionCarousal;
