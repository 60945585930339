import React from "react";
import Navigation from "./Navigation/Navigation";
import MainCarousel from "./HomeCarusal/MainCarousel";
import SectionCarousal from "./HomeSecationCarusal/SectionCarousal";
import Product from "./Product/Product";

import Contact from "./About/Contact";
import Footer from "./About/Footer";

export const Home = () => {
  return (
    <div>
      <Navigation />
      <MainCarousel />
      <SectionCarousal className="my-4" />
      <Product />

      <Contact />
      <Footer />
    </div>
  );
};
