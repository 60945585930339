import "./App.css";
import { Routes, Route } from "react-router-dom";
import MainCarousel from "./Customor/Components/HomeCarusal/MainCarousel";
import Navigation from "./Customor/Components/Navigation/Navigation";
import { Home } from "./Customor/Components/Home";
import Contact from "./Customor/Components/About/Contact";
import { MainContact } from "./Customor/Components/About/MainContact";
import ErrorPage from "./ErrorPage";
import { ToastContainer } from "react-toastify";
import AminContact from "./Admin/components/AminContact";
import { IoLogoWhatsapp } from "react-icons/io";
import First_page from "./Customor/Components/DetalisPage.js/First_Page";
import Second_Page from "./Customor/Components/DetalisPage.js/Second_Page";
import Third_Page from "./Customor/Components/DetalisPage.js/Third_Page";
import Forth_Page from "./Customor/Components/DetalisPage.js/Forth_Page";
import Fifth_Page from "./Customor/Components/DetalisPage.js/Fifth_Page";
import Sixth_Page from "./Customor/Components/DetalisPage.js/Sixth_Page";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<MainContact />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/AdminContact" element={<AminContact />} />
        <Route path="/first_page" element={<First_page />} />
        <Route path="/second_page" element={<Second_Page/>} />
        <Route path="/Third_Page" element={<Third_Page/>} />
        <Route path="/Forth_Page" element={<Forth_Page/>} />
        <Route path="/Fifth_Page" element={<Fifth_Page/>} />
        <Route path="/Sixth_Page" element={<Sixth_Page/>} />

      </Routes>
      <a
      

      href="https://wa.me/919558612112?text=Hello How Can I hepl you ?"
        target="_blank"
        rel="noopener noreferrer"
        className="textDecoration"
      >
        <IoLogoWhatsapp className=" wpIcon" />
      </a>
    </div>
  );
}

export default App;
