import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './page.css'

const First_page = () => {
  return (
    <Container>

      <Row>
        <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
          <Card style={{ width: '18rem', }}>
            <Card.Img className='img_edit' variant="top" src={require('../img/ca(1).jpg')} />
            <Card.Title>Cabbage</Card.Title>
          </Card>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
          <Card style={{ width: '18rem', }}>
            <Card.Img className='img_edit' variant="top" src={require('../img/ca(9).jpg')} />
            <Card.Title>Tomato</Card.Title>
          </Card>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
          <Card style={{ width: '18rem' }}>
            <Card.Img className='img_edit' variant="top" src={require('../img/ca(8).jpg')} />
            <Card.Title>Potato</Card.Title>
          </Card>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
          <Card style={{ width: '18rem' }}>
            <Card.Img className='img_edit' variant="top" src={require('../img/ca(2).jpg')} />
            <Card.Title>Cucumber</Card.Title>
          </Card>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
          <Card style={{ width: '18rem' }}>
            <Card.Img className='img_edit' variant="top" src={require('../img/ca(6).jpg')} />
            <Card.Title>Lemon</Card.Title>
          </Card>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
          <Card style={{ width: '18rem' }}>
            <Card.Img className='img_edit' variant="top" src={require('../img/ca(3).jpg')} />
            <Card.Title>Drumstick</Card.Title>
          </Card>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
          <Card style={{ width: '18rem' }}>
            <Card.Img className='img_edit' variant="top" src={require('../img/ca(5).jpg')} />
            <Card.Title>Green Chilli</Card.Title>
          </Card>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "30px" }}>
          <Card style={{ width: '18rem' }}>
            <Card.Img className='img_edit' variant="top" src={require('../img/ca(7).jpg')} />
            <Card.Title>Onion</Card.Title>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default First_page