import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { Button } from "@mui/material";


import { useLocation, useNavigate } from "react-router-dom";

import { styled, alpha } from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";

import logo from "../../Components/img/ca(26).PNG"; // Import your logo image
import "./style.css";

const navigation = {
  pages: [
    { name: "Home", href: "/" },

    { name: "Contact", href: "/Contact" },
  ],
};

export default function Navigation() {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className="z-50 bg-black">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="relative inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md"
                    onClick={() => setOpen(false)}
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="px-4 py-6 space-y-6 border-t  border-gray-200">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="block p-2 -m-2 font-medium text-black"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative z-50 bg-black">
        <nav
          aria-label="Top"
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
        >
          <div className="border-gray-200 ">
            <div className="flex items-center justify-between">
              <div className="w-full">
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    objectFit: "contain",
                    height: "50px",
                    width: "auto",
                  }}
                  className="h-8 mr-2 "
                />{" "}
              </div>

              <div className="flex items-center h-16">
                <button
                  type="button"
                  className="relative p-2 text-gray-400 bg-white rounded-md lg:hidden"
                  onClick={() => setOpen(true)}
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                </button>
                <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                  <div className="flex h-full space-x-8">
                    <ul id="primary">
                      <li>
                        <a href="/" className="">
                          Home
                        </a>
                      </li>

                      <li>
                        <a href="/Contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </Popover.Group>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
