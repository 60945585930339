import React from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useNavigate } from "react-router-dom";
import './Procss.css'
const Product = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="title">Products</div>
      <Container>


        <Row>
          <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
            <Card  className="card" style={{ width: '18rem'}}>
              <Card.Img className='img_editbg'variant="top" src={require('../img/ca(4).jpg')} />
              <Card.Body>
                <Card.Title>Vegetables</Card.Title>
                <Button variant="primary" ><a href="/first_page" >View More</a></Button>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
            <Card style={{ width: '18rem' }}>
              <Card.Img className='img_editbg' variant="top" src={require('../img/ca(19).jpg')} />
              <Card.Body>
                <Card.Title>Spices</Card.Title>
                <Button variant="primary" ><a href="/second_page" >View More</a></Button>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
            <Card style={{ width: '18rem' }}>
              <Card.Img className='img_editbg' variant="top" src={require('../img/ca(46).jpg')} />
              <Card.Body>
                <Card.Title>Pulses</Card.Title>
                <Button variant="primary"><a href="/Third_Page" >View More</a></Button>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
            <Card style={{ width: '18rem' }}>
              <Card.Img className='img_editbg' variant="top" src={require('../img/ca(55).jpg')} />
              <Card.Body>
                <Card.Title>Mix Fruit</Card.Title>
                <Button variant="primary"><a href="/Forth_Page" >View More</a></Button>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
            <Card style={{ width: '18rem' }}>
              <Card.Img className='img_editbg' variant="top" src={require('../img/ca(66).jpg')} />
              <Card.Body>
                <Card.Title>Dry Fruit</Card.Title>
                <Button variant="primary"><a href="/Fifth_Page" >View More</a></Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Product;
